export const colorShade = (col: string, index: number) => {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  const [r, g, b] = col.match(/.{2}/g) as RegExpMatchArray;
  const [rNum, gNum, bNum] = [
    Math.floor(parseInt(r, 16) * index),
    Math.floor(parseInt(g, 16) * index),
    Math.floor(parseInt(b, 16) * index),
  ];

  const rStr = Math.max(Math.min(255, rNum), 0).toString(16);
  const gStr = Math.max(Math.min(255, gNum), 0).toString(16);
  const bStr = Math.max(Math.min(255, bNum), 0).toString(16);

  const rr = (rStr.length < 2 ? '0' : '') + rStr;
  const gg = (gStr.length < 2 ? '0' : '') + gStr;
  const bb = (bStr.length < 2 ? '0' : '') + bStr;

  return `#${rr}${gg}${bb}`
}
import { useCallback, useMemo } from 'react';
import { useStore } from './store';
import { StoreActionType } from './model/storeActionType';
import { GlobalStore } from './model/global-store.model';
import { AppSettings } from '../models/app-settings';
import { StoreAction } from './model/store-action';

export const useGlobalStore = () => {
  const { state, dispatch }: { state: GlobalStore, dispatch: (action: StoreAction) => void } = useStore();

  const baseApiUrl = useMemo(() => {
    return process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_LOCAL_BACKEND_URL || ''
      : window.location.hostname.replace(/^docs\./, '');
  }, []);

  const appSettingsApi = {
    appSettings: state.appSettings,
    setAppSettings: useCallback((appSettings: AppSettings) => {
      dispatch({ type: StoreActionType.setAppSettings, appSettings });
    }, [dispatch]),
  };

  return {
    ...appSettingsApi,
    baseApiUrl,
  };
};

import { grpc } from '@improbable-eng/grpc-web';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { UnaryMethodDefinition } from '@improbable-eng/grpc-web/dist/typings/service';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';

export async function makeGrpcRequest<TReq extends ProtobufMessage, TRes extends ProtobufMessage>(
  methodDescriptor: UnaryMethodDefinition<TReq, TRes>,
  request: ProtobufMessage,
): Promise<ReturnType<TRes['toObject']>> {
  return new Promise(
    (resolve, reject) => {
      grpc.unary(methodDescriptor, {
        request,
        host: '/api',
        onEnd: (response: UnaryOutput<ProtobufMessage>) => {
          const { status, message } = response;

          if (status === grpc.Code.OK && message) {
            const responseData = message.toObject() as ReturnType<TRes['toObject']>;
            resolve(responseData);
          } else {
            console.error(`Response code: ${ status }`)
            reject(response);
          }
        },
      });
    },
  );
}

import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Spinner } from './components/spinner/Spinner';
import styles from './styles/App.module.scss';
import { AppSettings } from './models/app-settings';
import { useGlobalStore } from './global-store/global-store';
import { colorShade } from './utils/helpers/shade-color';
import { useApi } from './utils/api';
import { convertPartnerInfoToAppSettings } from './utils/helpers/convert-partner-info-to-app-settings';

const LocalizedRoutes = React.lazy(() => import('./router/routes'));
const ErrorPage = React.lazy(() => import('./components/error-page/ErrorPage'));

const MOBILE_BREAKPOINT = 820;

function App() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < MOBILE_BREAKPOINT);
  const [isLoading, setIsLoading] = useState(true);
  const [isPartnerInfoError, setIsPartnerInfoError] = useState(false);

  const { setAppSettings, appSettings: { style } } = useGlobalStore();
  const { requestPartnerInfo } = useApi();
  const rootRef = useRef(document.documentElement);

  const {
    primaryColor,
    secondaryColor,
    activeColor,
    successColor,
    dangerColor,
    warningColor,
    linkColor,
  } = useMemo(() => {

    return {
      primaryColor: style?.primaryColor || '#1f1f1f',
      secondaryColor: style?.secondaryColor || '#797979',
      activeColor: style?.activeColor || '#38aaf9',
      successColor: style?.successColor || '#75c03d',
      dangerColor: style?.dangerColor || '#d22a2a',
      warningColor: style?.warningColor || '#ff7630',
      linkColor: style?.linkColor || '#0871da',
    }
  }, [style]);

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth < MOBILE_BREAKPOINT && !isMobile) {
      setIsMobile(true);
    } else if (window.innerWidth >= MOBILE_BREAKPOINT && isMobile) {
      setIsMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    requestPartnerInfo()
      .then((response) => {
        const appSettings: AppSettings = convertPartnerInfoToAppSettings(response);
        setAppSettings(appSettings);
      })
      .catch(() => {
        setIsPartnerInfoError(true);
      })
      .finally(() => setIsLoading(false));
  }, [requestPartnerInfo, setAppSettings]);

  useEffect(() => {
      if (primaryColor && rootRef.current) {
        rootRef.current.style.setProperty('--primary-color', primaryColor);
        rootRef.current.style.setProperty('--primary-color-lighter', colorShade(primaryColor, 1.1));
        rootRef.current.style.setProperty('--primary-color-light', colorShade(primaryColor, 1.3));
        rootRef.current.style.setProperty('--primary-color-darker', colorShade(primaryColor, .8));

        rootRef.current.style.setProperty('--secondary-color', secondaryColor);
        rootRef.current.style.setProperty('--active-color', activeColor);
        rootRef.current.style.setProperty('--active-color-darker', colorShade(activeColor, .9));
        rootRef.current.style.setProperty('--success-color', successColor);
        rootRef.current.style.setProperty('--danger-color', dangerColor);
        rootRef.current.style.setProperty('--warning-color', warningColor);
        rootRef.current.style.setProperty('--warning-color-darker', colorShade(warningColor, .9));

        rootRef.current.style.setProperty('--link-color', linkColor);
        rootRef.current.style.setProperty('--link-color-hover', colorShade(linkColor, .9));
      }
    }, [activeColor, dangerColor, linkColor, primaryColor, secondaryColor, successColor, warningColor]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, false);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <div className={ `${ styles.App }` }>
      <Suspense fallback={ <Spinner /> }>
        { isLoading
          ? <Spinner />
          : isPartnerInfoError
            ? <ErrorPage />
            : <LocalizedRoutes isMobile={ isMobile } />
        }
      </Suspense>
    </div>
  );
}

export default App;

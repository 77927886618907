import { createContext, useContext, useReducer } from 'react';
import { StoreActionType } from './model/storeActionType';
import { GlobalStore } from './model/global-store.model';
import { StoreAction } from './model/store-action';

const initialState: GlobalStore = GlobalStore.emptyStore();
const StoreContext = createContext({
  state: initialState,
  dispatch: (_: StoreAction) => {},
});

const reducer = (state: GlobalStore, action: StoreAction): GlobalStore => {
  switch (action.type) {
    case StoreActionType.setAppSettings: {
      return ({ ...state, appSettings: action.appSettings });
    }
    default:
      throw new Error(`Unhandled action type: ${ action.type }`);
  }
};

export const StoreProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={ { state, dispatch } }>
      { children }
    </StoreContext.Provider>
  );
};

export const useStore = (): { state: GlobalStore, dispatch: (action: StoreAction) => void } => useContext(StoreContext);

import { GetCurrentPartnerRequest } from 'tnxhub-api/js/adminsrv/v1/partner_pb';
import { makeGrpcRequest } from './helpers/make-grpc-request';
import { PartnerService } from 'tnxhub-api/js/adminsrv/v1/partner_pb_service';
import { PartnerInfo } from '../models/partner-info';
import { useCallback } from 'react';

export function useApi() {
  const requestPartnerInfo = useCallback((): Promise<PartnerInfo> => {
    return makeGrpcRequest(PartnerService.GetCurrentPartner, new GetCurrentPartnerRequest())
      .then((response) => response.partner as unknown as PartnerInfo);
  }, [])

  return { requestPartnerInfo };
}
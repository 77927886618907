import { PartnerInfo } from '../../models/partner-info';
import { AppSettings } from '../../models/app-settings';

export function convertPartnerInfoToAppSettings(partnerInfo: PartnerInfo): AppSettings {
  return {
    logoUrl: partnerInfo.logoUrl,
    logoIconURL: partnerInfo.logoIconUrl,
    salesEmail: partnerInfo.salesEmail,
    companyName: partnerInfo.name,
    style: partnerInfo.style,
    partnerLegalInfo: partnerInfo.partnerLegalInfo,
    privacyPolicyUrl: partnerInfo.privacyPolicyUrl,
    termsOfUseUrl: partnerInfo.termsOfUseUrl,
  };
}

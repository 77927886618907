import { AppSettings } from '../../models/app-settings';

const DEFAULT_APP_SETTINGS: AppSettings = {
  logoUrl: '',
  logoIconURL: '',
  companyName: '',
  salesEmail: '',
  partnerLegalInfo: '',
  privacyPolicyUrl: '',
  termsOfUseUrl: '',
};

export class GlobalStore {
  constructor(
    public appSettings: AppSettings,
  ) {}

  public static emptyStore(): GlobalStore {
    return {
      appSettings: DEFAULT_APP_SETTINGS,
    };
  }
}

import { hydrate, render } from 'react-dom';
import './styles/index.scss';
import App from './App';

import smoothscroll from 'smoothscroll-polyfill';

import './i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreProvider } from './global-store/store';

const root = document.getElementById('root');

smoothscroll.polyfill();


if (root?.hasChildNodes()) {
  hydrate(<Router><StoreProvider><App /></StoreProvider></Router>, root);
} else {
  render(<Router><StoreProvider><App /></StoreProvider></Router>, root);
}

import i18n, { Module } from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import { TranslationNamespace } from './models/translation-namespace';

i18n
  .use(backend)
  .use(initReactI18next as Module)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: [TranslationNamespace.DOCS],
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
